import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import PlusIcon from "src/ui/icons/plus.svg"

export function AddButton({
  adornment: _adornment,
  children,
  ...props
}: {
  adornment?: React.ReactNode
  children: React.ReactNode
} & TMButtonProps) {
  return (
    <MButton {...props} endIcon={<PlusIcon width={getIconSize(props.size)} />}>
      {children}
    </MButton>
  )
}

function getIconSize(size: TMButtonProps["size"]) {
  switch (size) {
    case "medium":
      return 20
    case "small":
      return 16
    default:
      return 20
  }
}
